<template>
  <div class="container_con" style="background: none;">
    <el-card style="margin-bottom: 15px;" v-if="data">
      <div class="con_title"><span></span> 报价单详情</div>
      <div style="line-height: 24px; font-size: 14px;">
        <el-row :gutter="20" style="margin-bottom: 10px;">
          <el-col :span="6">开票日期：{{ data.sale_quote_date }}</el-col>
          <el-col :span="6">业务员：{{ data.admin_name }}</el-col>
          <el-col :span="6">部门：{{ data.department_name }}</el-col>
          <el-col :span="6">客户：{{ data.merchant.merchant_name }}</el-col>
        </el-row>
        <!-- <el-row :gutter="20">
              <el-col :span="6">单据编号：{{data.sale_plan_no}}</el-col>
              <el-col :span="6">单位编号：{{data.merchant.merchant_no}}</el-col>
              <el-col :span="6">备注：{{data.remark}}</el-col>
          </el-row> -->
      </div>
      <div class="" style="margin-top: 10px; font-size: 14px;">
        <div style="padding-bottom: 20px;">销售产品：</div>
        <div class="">
          <el-table :data="sale_quote_product" style="width: 100%">
            <el-table-column type="index" width="50">
            </el-table-column>
            <el-table-column label="产品编号">
              <template v-slot="scope">
                {{ scope.row.product.product_no }}
              </template>
            </el-table-column>
            <el-table-column label="品名">
              <template v-slot="scope">
                {{ scope.row.product.product_name }}
              </template>
            </el-table-column>
            <!-- <el-table-column label="货位名称">
              <template v-slot="scope">
                {{ scope.row.warehouse_name }}
              </template>
            </el-table-column> -->
            <el-table-column label="型号规格">
              <template v-slot="scope">
                {{ scope.row.product.model }}
              </template>
            </el-table-column>
            <el-table-column label="生产厂家">
              <template v-slot="scope">
                {{ scope.row.product.manufacturer }}
              </template>
            </el-table-column>
            <el-table-column label="包装单位">
              <template v-slot="scope">
                {{ scope.row.pack_unit_name }}
              </template>
            </el-table-column>
            <el-table-column label="数量">
              <template v-slot="scope">
                {{ scope.row.product_num }}
              </template>
            </el-table-column>
            <el-table-column label="进价">
              <template v-slot="scope">
                {{ scope.row.product_price }}
              </template>
            </el-table-column>
            <el-table-column label="报价">
              <template v-slot="scope">
                {{ scope.row.quote_price }}
              </template>
            </el-table-column>
            <el-table-column label="金额">
              <template v-slot="scope">
                <span style="color: red;">{{ scope.row.amount }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="批号/生产日期">
              <template v-slot="scope">
                {{ scope.row.batch_number }}
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="有效期至">
              <template v-slot="scope">
                {{ scope.row.valid_until || '长效期' }}
              </template>
            </el-table-column> -->
            <el-table-column label="注册证名">
              <template v-slot="scope">
                {{ scope.row.product.register_cert_name }}
              </template>
            </el-table-column>
            <el-table-column label="注册证号">
              <template v-slot="scope">
                {{ scope.row.product.register_cert_no }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-button @click="$router.go(-1)" style="margin-top: 20px;">返回</el-button>
    </el-card>

  </div>
</template>

<script>
export default {
  name: "addsalesOut",
  data() {
    return {
      id: '',
      is_ajax: false,
      form: {

      },
      list: [],
      data: '',
      sale_quote_product: []
    };
  },
  created() {
    this.id = this.$route.query.id || '';
    this.get_data();
  },
  methods: {
    get_data() {
      this.$httpGet("/backend/SaleQuote/read", { id: this.id }).then((res) => {
        if (res.status == 200) {
          this.sale_quote_product = res.data.sale_quote_product;
          this.data = res.data
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
    },

  },
};
</script>

<style lang="scss" scoped>
.table_Box {
  padding-left: 50px;
  font-size: 14px;

  .table_title {
    span {
      color: #1676e0;
      cursor: pointer;
    }
  }

  .el-table {
    margin: 30px 0;
  }
}

.top_box {
  display: flex;
  justify-content: space-around;
}

.examin {
  display: flex;
  justify-content: space-between;
}</style>
